type GtmConsentAction = 'default' | 'update'
type GtmConsentValue = 'granted' | 'denied' | 'auto'
/*
const GtmConsentTypes = [
  'ad_storage',
  'ad_user_data',
  'ad_personalization',
  'analytics_storage',
  'functionality_storage',
  'personalization_storage',
  'security_storage'
]
type GtmConsentType = (typeof GtmConsentTypes)[number]
type GtmConsentsRecord = Record<GtmConsentType, GtmConsentValue>
*/

const CookieGroup = ['required', 'marketing']
type CookieGroupType = (typeof CookieGroup)[number]

declare function setGtmConsents(action: GtmConsentAction, value: GtmConsentValue): void
declare function readConsentCookie(): ReadonlyArray<CookieGroupType> | undefined
declare function writeConsentCookie(values: ReadonlyArray<CookieGroupType>, expires: boolean): void
declare const dataLayer: Array<unknown>

export const isConsentRequired = (): boolean => !readConsentCookie()

export const updateGtmConsents = (marketing: boolean): void => {
  setGtmConsents('update', marketing ? 'granted' : 'denied')
  setTimeout(() => {
    // eslint-disable-next-line functional/immutable-data
    dataLayer.push({ event: 'Consent Updated' })
  }, 500)
}

export const setConsentCookie = (marketing: boolean): void =>
  writeConsentCookie(marketing ? ['required', 'marketing'] : ['required'], true)
