import styled, { css } from 'styled-components'

export const CustomerLayoutStyled = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    a {
      color: ${theme.colors.brown};
      text-decoration-color: ${theme.colors.brown};
      text-decoration: none;
      font-weight: 400;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  `
)
