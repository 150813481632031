import { Outlet } from 'react-router-dom'
import { CustomerLayout } from '@layout/CustomerLayout'

const Customer = (): React.ReactElement => {
  return (
    <CustomerLayout>
      <Outlet />
    </CustomerLayout>
  )
}

export default Customer
