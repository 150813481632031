import styled, { css } from 'styled-components'
export const CookieConsentStyled = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(5)};
    z-index: 10;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    transition: all 0.2s;
    display: flex;
    flex-direction: column;
    background: ${theme.colors.grey300};
    border: 1px solid ${theme.colors.grey600};

    h1 {
      font-size: ${theme.global.typography.size['size-20']};
    }

    h2 {
      font-size: ${theme.global.typography.size['size-20']};
    }

    p {
      font-size: ${theme.global.typography.size['size-16']};
    }

    .MuiCollapse-wrapper {
      max-height: 70vh;
      flex-shrink: 1;
      overflow-y: auto;
    }
    .MuiCheckbox-root,
    .MuiFormControlLabel-label {
      font-size: ${theme.global.typography.size['size-16']};
    }
    .MuiButton-root {
      font-size: ${theme.global.typography.size['size-16']};
    }

     {
      ${theme.breakpoints.down('md')} {
        padding: ${theme.spacing(3)};
        h1 {
          font-size: ${theme.global.typography.size['size-20']};
        }

        h2 {
          font-size: ${theme.global.typography.size['size-20']};
        }

        p {
          font-size: ${theme.global.typography.size['size-16']};
        }
        .MuiCollapse-wrapper {
          max-height: 50vh;
        }

        .MuiCheckbox-root,
        .MuiFormControlLabel-label {
          font-size: ${theme.global.typography.size['size-16']};
        }
        .MuiButton-root {
          font-size: ${theme.global.typography.size['size-16']};
        }
      }
    }
  `
)
