import * as React from 'react'
import { CookieConsent } from '@pages/Customer/CookieConsent'
import { CustomerLayoutStyled } from './CustomerLayout.styles'

type CustomerLayoutProps = {
  children: React.ReactNode
}

export const CustomerLayout = ({ children }: CustomerLayoutProps): React.ReactElement => {
  return (
    <CustomerLayoutStyled>
      <CookieConsent />
      {children}
    </CustomerLayoutStyled>
  )
}
