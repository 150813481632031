import * as React from 'react'
import { useTranslation } from 'react-i18next'
import * as z from 'zod'
import { isConsentRequired, setConsentCookie, updateGtmConsents } from '@utils/gtm-utils'
import { Box, Collapse } from '@mui/material'
import { Text } from '@controls/Text'
import { Button } from '@controls/Button'
import { CookieConsentStyled } from './CookieConsentStyled'
import { Form, FormCheckbox1, useFormTyped } from '@controls/Form'

const FormType = z.object({
  required: z.boolean(),
  marketing: z.boolean()
})
type FormType = z.infer<typeof FormType>

export const CookieConsent: React.FC = () => {
  const { t } = useTranslation()

  const [showConsent, setShowConsent] = React.useState(isConsentRequired)
  const [expanded, setExpanded] = React.useState(false)

  const setConsentOptions = React.useCallback((marketing: boolean) => {
    setConsentCookie(marketing)
    updateGtmConsents(marketing)
    setShowConsent(false)
  }, [])

  const handleAcceptAll = React.useCallback(() => {
    setConsentOptions(true)
  }, [setConsentOptions])

  const handleSubmit = React.useCallback(
    (values: FormType) => {
      setConsentOptions(values.marketing)
    },
    [setConsentOptions]
  )

  const form = useFormTyped({
    resolver: FormType,
    onSubmit: handleSubmit,
    values: { required: true, marketing: false }
  })

  return (
    showConsent && (
      <CookieConsentStyled>
        <Form f={form}>
          <Text color="brown" component="h1">
            {t('global.consent.cookie.title')}
          </Text>
          <Text whiteSpace="pre-wrap" component="p">
            {t('global.consent.cookie.text')}
          </Text>
          <Collapse in={expanded} timeout="auto" unmountOnExit className="additional">
            <Text color="brown" component="h2">
              {t('global.consent.cookie.additional.title')}
            </Text>
            <Text whiteSpace="pre-wrap" component="p">
              {t('global.consent.cookie.additional.text')}
            </Text>
            <Box
              display="flex"
              justifyContent="flex-start"
              mt={3}
              mb={3}
              sx={{ flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' } }}
            >
              <FormCheckbox1
                size="small"
                form={form}
                name="required"
                disabled={true}
                label={t('global.consent.cookie.consentTypes.required')}
              />
              <FormCheckbox1
                size="small"
                form={form}
                name="marketing"
                label={t('global.consent.cookie.consentTypes.marketing')}
              />
            </Box>

            <Box pb={3}>
              <Button color="brown" type="submit">
                {t('global.consent.cookie.buttons.acceptSelected')}
              </Button>
            </Box>
          </Collapse>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            gap={3}
            mt={3}
            sx={{ flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'auto', md: 'center' } }}
          >
            {!expanded && (
              <Button color="brown" onClick={() => setExpanded(true)}>
                {t('global.consent.cookie.buttons.customize')}
              </Button>
            )}
            <Button color="success" onClick={handleAcceptAll}>
              {t('global.consent.cookie.buttons.acceptAll')}
            </Button>
          </Box>
        </Form>
      </CookieConsentStyled>
    )
  )
}
